<template>
  <div class="page-withdraw">
    <my-header title="Withdraw">
      <span name="right" class="header-right" @click="toHistory">History</span>
    </my-header>
    <div class="account-box">
      <h3 class="title">Account Amount</h3>
      <h4 class="price-info">
        <span>USDT</span>
        <i>{{ (userInfo.balance || 0).toFixed(2) }}</i>
      </h4>
      <p>*You will receive your withdrawal within an hour</p>
    </div>

    <div class="content">
      <h3 class="title">Withdraw Method</h3>
      <p class="tips">Withdraw will be transferred to cryptocurrency wallet</p>

      <h3 class="title space-between">
        Withdraw Amount

        <div class="button" @click="WithdrawAll">Withdraw All</div>
      </h3>

      <div class="input-box">
        <input
          type="number"
          v-model="price"
          @blur="blurPrice"
          placeholder="Type withdraw Amount"
        />
      </div>
      <h3 class="title space-between">Security PIN</h3>

      <div class="input-box">
        <input type="password" v-model="pin" placeholder="Type Security PIN" />
      </div>

      <div class="button-submit" @click="Withdraw">Submit</div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
export default {
  components: { MyHeader },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      price: "",
      pin: "",
    };
  },
  methods: {
    toHistory() {
      this.$router.push("/platform/withdraw-history");
    },
    blurPrice() {
      if (this.price) {
        this.price = (+this.price).toFixed(2);
      }
    },
    WithdrawAll() {
      this.price = (this.userInfo.balance || 0).toFixed(2);
    },
    async Withdraw() {
      if (!this.price.trim() || !this.pin.trim()) {
        this.$dialog
          .alert({
            title: "Tips",
            message: "Please fill in the complete information",
            confirmButtonText: "Sure",
          })
          .then(() => {});
      } else if (!this.userInfo.usdtUrl) {
        this.$dialog
          .alert({
            title: "Tips",
            message: "Please bind your Usdt address before withdrawing cash!",
            confirmButtonText: "Sure",
          })
          .then(() => {});
      } else if (this.price > this.userInfo.balance) {
        this.$dialog.alert({
          title: "Tips",
          message: "Insufficient available balance",
          confirmButtonText: "Sure",
        });
      } else {
        try {
          const data = await api.withDraw({
            password: this.pin,
            price: this.price,
          });

          this.$dialog.alert({
            title: "Tips",
            message:
              "The withdrawal application is successful, please wait for review",
            confirmButtonText: "Sure",
          });
          this.price = "";
          this.pin = "";
          this.$emit("refreshUserInfo");
        } catch (e) {}
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-withdraw {
  padding: 90px 24px 24px;
}

.header-right {
  height: 44px;
  line-height: 44px;
  padding-right: 8px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}
.account-box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  height: 135px;
  background: url(../../assets/images/account-bg.png) no-repeat;
  background-size: cover;
  border-radius: 10px;
  line-height: 1.6;

  h3.title {
    color: #fff;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  h4.price-info {
    color: #fff;
    font-weight: 700;
    margin-top: 10px;
    span {
      font-size: 15px;
    }

    i {
      font-size: 22px;
      margin-left: 10px;
      font-style: normal;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }
}
.content {
  line-height: 1.6;
  h3.title {
    color: #25abc3;
    font-weight: 700;
    margin-top: 16px;

    &.space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .button {
      width: 100px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      border-radius: 3px;
      background: #1dc0b9;
      border: 1px solid #49dad4;
      font-weight: 400;
    }
  }

  p.tips {
    font-size: 12px;
  }

  .input-box {
    height: 46px;
    border-radius: 3px;
    padding: 0 10px;
    background: #fff;
    margin-top: 5px;
    border: 2px solid #c4e8ef;

    input {
      height: 100%;
      width: 100%;
      color: #333;
      font-size: 14px;
      background: #fff;
      outline: none;
      border: 0;
      box-sizing: border-box;
    }
  }

  .button-submit {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-image: linear-gradient(90deg, #25abc4, #1acbb5);
    background-size: cover;
    color: #fff;
    margin: 20px auto;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>