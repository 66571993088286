export default {
    cancel:'Annulation',
    login:{
        title:'Se connecter',
        tip:'Veuillez vous connecter pour accéder à plus de contenu',
        Username:'Nom d\'utilisateur',
        Password:'Mot de passe',
        Forgot_password:'Mot de passe oublié',
        Login_Now:'Se connecter maintenant',
        Register_Now:'Inscrivez - vous maintenant',
        phone_username:'Téléphone / nom d\'utilisateur',
        Type_Password:'Tapez le mot de passe',
        lang:'Langue',
        tip1:'Service à la clientèle WhatsApp',
        tip2:'Télégramme de service à la clientèle',
    },
    register:{
        title:'Enregistrement',
        tip:'Veuillez vous inscrire pour accéder à plus de contenu',
        Security_PIN:'Sécurité pin',
        Type_Security_PIN:'Tapez un code PIN de sécurité',
        Phone_Number:'Numéro de téléphone',
        Type_Phone_Number:'Tapez le numéro de téléphone',
        Invitation_Code:'Code d\'invitation',
        Type_Invitation_Code:'Type Code d\'invitation',
        Agree_with:'D\'accord',
        User_Registration_Agreement:'Contrat d\'enregistrement de l\'utilisateur',
        tip1:'Veuillez accepter le contrat d\'inscription de l\'utilisateur',
        tip2:'Veuillez taper votre téléphone / nom d\'utilisateur',
        tip3:'Veuillez entrer votre mot de passe',
        tip4:'Veuillez entrer un numéro de téléphone',
        tip5:'Veuillez saisir le Code d\'invitation',
        tip6:'Veuillez saisir un code PIN de sécurité',
        tip7:'Inscription réussie, veuillez vous connecter',
    },
    home:{
        title:'Sanctuaire de Dev',
        Welcome:'Bonjour, bienvenue',
        tip1:'Bienvenue sur notre plateforme!',
        VIP_Level:'Niveau VIP',
        View_More:'Voir plus',
        Starting:'Démarrage',
        Certificate:'Certificats',
        Withdraw:'Retrait',
        Deposit:'Dépôt',
        Home:'Accueil',
        Records:'Records',
        Event:'Activités',
        About_Us:'À propos de nous',
        Credibility_Value:'Valeur de crédibilité',
        vip_title: 'Niveau VIP',
        vip1: {
            tip1: 'VIP 1 Optimisez votre application',
            tip2: 'Recevez un ensemble de 40 tâches de données d\'application',
            tip3: 'Bénéfice de 0.5% par demande',
            tip4: 'Activation avec 10USDT',
        },
        vip2: {
            tip1: 'VIP 2 Optimisez votre application',
            tip2: 'Recevez un ensemble de 50 tâches de données d\'application',
            tip3: 'Bénéfice de 0.6% par demande',
            tip4: 'Activation avec 1500USDT',
        },
        vip3: {
            tip1: 'VIP 3 Optimisez votre application',
            tip2: 'Recevez un ensemble de 55 tâches de données d\'application',
            tip3: 'Bénéfice de 0.8% par demande',
            tip4: 'Activation avec 5000USDT',
        },
        vip4: {
            tip1: 'VIP 4 Optimisez votre application',
            tip2: 'Recevez un ensemble de 60 tâches de données d\'application',
            tip3: 'Bénéfice de 1% par demande',
            tip4: 'Activation avec 10000USDT',
        },
    },
    start:{
        tip:'Top applications de la semaine',
        profit:'Bénéfices',
        start:'Commencer',
        Today_profit:'Gains',
        tip1:'Il sera mis à jour quotidiennement. Seuls les bénéfices d\'aujourd\'hui sont affichés ici',
        Every_Ratings:'Chaque score',
        Frozen:'Congelé',
        tip2:'Les bénéfices de chaque notation augmenteront le solde total des actifs',
        Important_Notice:'Avis importants',
        Business_Hours:'Heures d\'ouverture',
        tip3:'Pour toute assistance supplémentaire, veuillez contacter le service clientèle',
        tip4:'Le système met automatiquement à jour les revenus quotidiens',
    },
    menu:{
        tip1:'Dépôt',
        tip2:'Retirer',
        tip3:'Informations personnelles',
        tip4:'Transaction',
        tip5:'Portefeuille Usdt Bind',
        tip6:'Service à la clientèle',
        tip7:'Déconnexion du compte',
    },
    records:{
        menu1:'TOUS',
        menu2:'Soumission',
        menu3:'Complété',
        menu4:'En attente',
        status1:'Soumettez maintenant!',
        status2:'Complété',
    }
}