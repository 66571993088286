<template>
    <div class="cert-page">
        <div class="cert-content">
            <img src="../../assets/images/logo.png" alt="" />
            <h1 style="text-align: center;">System Maintaining</h1>
        </div>
    </div>
</template>
<script>
import api from "@/api/api";

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
    }
};
</script>
<style lang="less" scoped>
.cert-page {
  padding-top: 90px;

  .cert-content {
    padding: 0 24px 24px;
    text-align: center;
    img {
      max-width: 60%;
    }
  }
}
</style>