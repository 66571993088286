<template>
  <div class="page-change">
    <my-header title="Change Security PIN"></my-header>
    <div class="form-box">
      <div class="form-item">
        <div class="form-title">Original password</div>
        <div class="form-value">
          <div class="input-box">
            <input
              class="input"
              v-model="originPassword"
              type="password"
              placeholder="Original password"
            />
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="form-title">Password</div>
        <div class="form-value">
          <div class="input-box">
            <input
              v-model="password"
              class="input"
              type="password"
              placeholder="Password"
            />
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="form-title">Confirm password</div>
        <div class="form-value">
          <div class="input-box">
            <input
              v-model="confirmPassword"
              type="password"
              class="input"
              placeholder="Confirm password"
            />
          </div>
        </div>
      </div>

      <div class="default-button" @click="submitChangePassword">Submit</div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
export default {
  components: { MyHeader },
  data() {
    return {
      originPassword: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async submitChangePassword() {
      try {
        if (
          !this.originPassword.trim() ||
          !this.password.trim() ||
          !this.confirmPassword.trim()
        ) {
          this.$dialog
            .alert({
              title: "Tips",
              message: "Information cannot be empty",
              confirmButtonText: "Sure",
            })
            .then(() => {});
        } else {
          const data = await api.changePayPassword({
            originalPayPassword: this.originPassword,
            payPassword: this.password,
            repeatPayPassword: this.confirmPassword,
          });

          this.$toast.success("Change Security PIN is successful!");
        }
      } catch (e) {}
    },
  },
};
</script>
<style lang="less" scoped>
.page-change {
  padding-top: 90px;

  .form-box {
    padding: 0 16px;
    line-height: 1.6;
    .form-item {
      padding: 16px 0 0;
      .form-title {
        color: #25abc3;
        font-weight: 700;
      }

      .input-box {
        height: 46px;
        padding: 0 16px;

        .input {
          width: 100%;
          height: 100%;
          border: 0;
          padding: 0;
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>