import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import Loading from './components/Loading.vue'
import i18n from "@/i18n/lang";

import 'vant/lib/index.css';

Vue.use(Vant);
Vue.component('loading', Loading)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
