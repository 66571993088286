<template>
    <div class="cert-page">
        <my-header title="Certificate"></my-header>
        <div class="cert-content">
            <img @click="previewImage" :src="imgPath" alt="" />
        </div>
    </div>
</template>
<script>
import MyHeader from "../../components/header.vue";
import api from "@/api/api";
export default {
    components: { MyHeader },
    data(){
        return {
            imgPath:''
        }
    },
    methods: {
        previewImage() {},
        async getImg(){
            const data = await api.getCertImage({});
            this.imgPath = data.homeimg
            console.log(data)
        }
    },
    created() {
        this.getImg()
    }
};
</script>
<style lang="less" scoped>
.cert-page {
    padding-top: 90px;

    .cert-content {
        padding: 0 24px 24px;

        img {
            max-width: 100%;
        }
    }
}
</style>