import request from './request'

export default {
    register: data => request.post('/user/register', data),
    login: data => request.post('/user/login', data),
    getUserInfo: data => request.post('/user/getUserInfo', data),
    getFundList: data => request.post('/fund/getFundByUser', data),
    bindUsdtAddress: data => request.post('/userWallet/insUserWallet', data),
    withDraw: data => request.post('/withdrawal/insWithdrawal', data),
    startTask: data => request.post('/order/startTask', data),
    getOrderList: data => request.post('/order/getOrderList', data),
    submitOrder: data => request.post('/order/orderSub', data),
    changePassword: data => request.post('/user/upUserPassword', data),
    changePayPassword: data => request.post('/user/upUserPayPassword', data),
    getCustomerList: data => request.post('/customerService/getCustomerServiceById', data),
    getUserCurrentTask: data => request.post('/order/getUserOrderSum', data),
    getAllContext: data => request.post('/context/getAllContext', data),
    checkSecurityPin: data => request.post('/user/checkingPassword', data),
    getUserWithDrawList: data => request.post('/withdrawal/getWithdrawalByUser', data),
    getImage: data => request.post('/home/getHomeImg', data),
    getCertImage: data => request.post('/home/getCertImg', data),
    getWorkTime: data => request.post('/workTime/getWorkTime', data),
    getSysStatus: data => request.post('/home/sys', data),
    getRecharge: data => request.post('/fund/recharge', data)
}