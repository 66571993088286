<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
import api from './api/api';

export default {
    created() {
        this.getImage();
        this.getSysStatus();
    },
    methods: {
        async getImage() {
            const data = await api.getImage({});
            localStorage.setItem("IMAGE", data.homeimg);
            // console.log(data);
        },
        async getSysStatus() {
            const data = await api.getSysStatus({});
            if (data.data === '0') {
                this.$router.replace(`/maintenance`)
            }
            // console.log(data);
        },
    },
};
</script>
<style lang="less">
@import url(./assets/css/base.css);

#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
  "Microsoft Yahei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  margin: 0 auto;
  text-align: left;
  font-size: 14px;
}

.van-action-sheet__name {
  color: rgb(38, 170, 195);
  font-size: 14px;
}

.van-dialog {
  .van-dialog__confirm {
    color: rgb(38, 170, 195);
  }
}

.van-list__finished-text {
  display: none;
}

.default-button {
  height: 50px;
  line-height: 50px;
  background-image: linear-gradient(90deg, #25abc4, #1acbb5);
  background-size: cover;
  color: #fff;
  margin: 20px auto;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
</style>
