import { Locale } from 'vant'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

import enUS from 'vant/es/locale/lang/en-US'
import frFR from 'vant/es/locale/lang/fr-FR'

import frCnLocale from '@/lang/fr-FR'
import enUsLocale from '@/lang/en-US'
import Vue from "vue";

const messages = {
    'en-US': {
        ...enUS,
        ...enUsLocale
    },
    'fr-FR': {
        ...frFR,
        ...frCnLocale
    },
}

export const getLocale = () => {
    const cookieLanguage = Cookies.get('language')
    if (cookieLanguage) {
        // document.documentElement.lang = cookieLanguage
        return cookieLanguage
    }

    const language = navigator.language.toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale.toLowerCase()) > -1) {
            // document.documentElement.lang = locale
            return locale
        }
    }

    // Default language is english
    return 'en-US'
}

const CURRENT_LANG = getLocale()
Locale.use(CURRENT_LANG, messages[CURRENT_LANG])
VueI18n.install(Vue)
const i18n = new VueI18n({
    locale: CURRENT_LANG,
    messages
})
export default i18n
