<template>
  <div class="agreement include-header">
    <my-header :title="title"></my-header>

    <div class="content" v-html="html"></div>
  </div>
</template>
<script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
export default {
  components: { MyHeader },
  data() {
    return {
      title: "User Registration Agreement",
      html: ``,
    };
  },
  created() {
    if (this.$route.path === "/agreement") {
      this.title = "User Registration Agreement";
      this.html = `Welcome to simplecreature and its services!<br /><br />In order to protect
      the security of this website and the creator's software ("Software Use and
      Services"), you should read the following "Software and Services License"
      ("Software" or "Agreement"). You need to fully understand the terms and
      conditions, particularly the terms of service and restrictions, and the
      separate agreements for each term, and accept or reject liability.<br /><br />If
      you are 18 years of age or older, becoming a user of this website means
      that you have read and agreed to this agreement and related terms and
      conditions. Otherwise, you have no right to download, install software and
      enjoy this service.<br /><br /><br />(1) Protection of users’ personal
      information<br /><br />1.1 The protection of users’ personal information
      and creators’ information is the basic principle of this website. Ripe
      Media uses professional encrypted storage and transmission channels for
      all information to ensure user safety. If information is leaked without
      the consent of the original text, this site will be held legally
      responsible.<br /><br />1.2 In the process of using this service, the user
      needs to provide some necessary information. For example, in order to
      register the account, the user needs to fill in his or her mobile phone
      number and agree to the relevant terms and conditions of use. If the
      information provided by the user is incomplete, the user may be restricted
      during use.<br /><br />1.3 Under normal circumstances, users can modify
      the submitted information at any time. For security reasons (such as
      account retrieval services), users may not be able to change personal
      information at will after registration.<br /><br />1.4 simplecreature uses
      various security technologies and procedures and has a complete management
      system to protect users' personal information. Any use or unauthorized use
      at any time will be subject to legal proceedings. The final registered
      mobile phone number of the new pagepro account and the existing pagepro
      account cannot be changed at will.<br /><br />1.5 Under no circumstances
      will simplecreature disclose user information to companies and
      organizations outside simplecreature without the user's consent.<br /><br />1.6
      For persons under the age of 18, written information from a parent or law
      enforcement official is required before accessing the services of this
      website.<br /><br /><br />(B) User Responsibilities<br /><br />2.1 Users
      need to complete a set of tasks before they can apply for currency
      withdrawal.<br /><br />2.2 Users cannot withdraw cash during the
      optimization period.<br /><br />2.3 Users cannot cancel or skip tasks.<br /><br />2.4
      Any withdrawal of funds in a user's account exceeding100,000 is subject to
      a 20% withdrawal fee, which will be refunded to the user immediately upon
      receipt of the withdrawal.<br /><br /><br />(C) Terms and Conditions<br /><br />3.1
      Agreement: The user contract and service terms are subject to the terms
      stipulated on the account. The user should provide relevant information
      and documents to this website. The user is an attachment to the contract
      terms.<br /><br />3.2 This website provides services to all users in
      accordance with these Terms. If you have any questions or other important
      concerns, please contact the department for feedback.`;
    } else if (this.$route.path === "/platform/tc") {
      this.title = "Terms And Conditions";
    } else if (this.$route.path === "/platform/event") {
      this.title = "Event";
    } else if (this.$route.path === "/platform/faq") {
      this.title = "FAQs";
    } else if (this.$route.path === "/platform/about") {
      this.title = "About Us";
    }

    this.getAllContext();
  },
  methods: {
    async getAllContext() {
      const data = await api.getAllContext({});
      console.log(data);
      console.log(data);
      const item = data.find((item) => item.title === this.title);
      if (item) {
        this.html = item.context.replace(/\n/gi, "<br />");
        console.log(this.html);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.agreement {
  .content {
    font-size: 16px;
    line-height: 1.6;
    padding: 24px 16px;

    /deep/ img {
      max-width: 100%;
    }
  }
}
</style>