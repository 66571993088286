<template>
  <div class="main-page">
    <my-header @showLeftMenu="showLeftMenu" :showBack="false" :title="$t('home.Starting')">
    </my-header>
    <div class="page-content">
      <h1 class="title">{{$t('start.tip')}}</h1>
      <div class="marquee">
        <ul class="first">
          <li v-for="(item, index) in imageList" :key="index">
            <img :src="item" alt="" />
          </li>
        </ul>

        <ul class="last">
          <li v-for="(item, index) in imageList" :key="index">
            <img :src="item" alt="" />
          </li>
        </ul>
      </div>
      <div class="app-box">
        <div class="app-item" v-for="(item, index) in appList" :key="index">
          <img :src="item" alt="" />
        </div>
      </div>

      <div class="number">
        {{ taskNumber }}/{{ taskNumberList[userInfo.levelId - 1] }}
      </div>
      <div class="level">
        <img :src="levelImage" alt="" />
        <span>{{ profitList[userInfo.levelId - 1] }} {{$t('start.profit')}}</span>
      </div>

      <div class="start-button" @click="toStart">{{$t('start.start')}}</div>

      <div class="price-info">
        <div class="price-item">
          <img src="../../assets/images/current-in.png" alt="" />
          <h3>{{$t('start.Today_profit')}}</h3>
          <h4>USDT {{ (userInfo.incomeForDay || 0).toFixed(2) }}</h4>
          <p>{{$t('start.tip1')}}</p>
        </div>

        <div class="price-item">
          <img src="../../assets/images/all-in.png" alt="" />
          <h3>{{$t('start.Every_Ratings')}}</h3>
          <p class="usdt">USDT {{ (userInfo.balance || 0).toFixed(2) }}</p>
          <p class="frozen">{{$t('start.Frozen')}}</p>
          <p class="usdt">USDT {{ (userInfo.freezeMoney || 0).toFixed(2) }}</p>
          <p>{{$t('start.tip2')}}</p>
        </div>
      </div>

      <div class="spe-tips">
        <h3 class="title">{{$t('start.Important_Notice')}}</h3>
        <p>{{$t('start.Business_Hours')}} {{ startTime }}:00:00 - {{ endTime }}:00:00</p>
        <p>{{$t('start.tip3')}}</p>
      </div>
    </div>

    <loading v-show="showLoading"></loading>

    <van-popup
      v-model="showTask"
      position="bottom"
      round
      :close-on-click-overlay="false"
    >
      <div class="task-box">
        <h2 class="title">
          Rating Submission
          <i class="close-icon van-icon van-icon-cross" @click="closeTask"></i>
        </h2>

        <img class="task-image" :src="taskDetail.goodsImg" alt="" />

        <h3 class="title">{{ taskDetail.goodsName }}</h3>
        <div class="price-box">
          <div class="price-item">
            <h4 class="title">Total amount</h4>
            <p>USDT {{ (taskDetail.price || 0).toFixed(2) }}</p>
          </div>
          <div class="price-item">
            <h4 class="title">Profit</h4>
            <p>USDT {{ (taskDetail.income || 0).toFixed(2) }}</p>
          </div>
        </div>

        <div class="price-line">
          <span>Creation time</span>
          <p>{{ taskDetail.createTime }}</p>
        </div>
        <div class="price-line">
          <span>Rating No.</span>
          <p>{{ taskDetail.orderNum }}</p>
        </div>

        <div class="button" @click="submitOrder">Submit</div>
      </div>
    </van-popup>
  </div>
</template>
  <script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { MyHeader },
  data() {
    return {
      showLoading: false,
      showTask: false,
      isClose: true,
      imageList: [
        require("../../assets/images/loop/9.png"),
        require("../../assets/images/loop/2.png"),
        require("../../assets/images/loop/3.png"),
        require("../../assets/images/loop/4.png"),
        require("../../assets/images/loop/5.png"),
        require("../../assets/images/loop/6.png"),
        require("../../assets/images/loop/7.png"),
        require("../../assets/images/loop/8.png"),
      ],
      appList: [
        require("../../assets/images/app/1.png"),
        require("../../assets/images/app/2.png"),
        require("../../assets/images/app/3.png"),
        require("../../assets/images/app/4.png"),
        require("../../assets/images/app/5.png"),
        require("../../assets/images/app/6.png"),
        require("../../assets/images/app/7.png"),
        require("../../assets/images/app/8.png"),
        require("../../assets/images/app/9.png"),
        require("../../assets/images/app/10.png"),
        require("../../assets/images/app/11.png"),
        require("../../assets/images/app/12.png"),
        require("../../assets/images/app/13.png"),
        require("../../assets/images/app/14.png"),
        require("../../assets/images/app/15.png"),
      ],
      taskDetail: {},
      taskNumber: 0,
      profitList: ["0.5%", "0.6%", "0.8%", "1%"],
      taskNumberList: [40, 50, 55, 60],
      levelImage: "",
      startTime: "",
      endTime: "",
    };
  },
  created() {
    this.getUserCurrentTask();
    this.getWorkTime();
    if (this.userInfo.levelId) {
      this.levelImage = require(`../../assets/images/level${this.userInfo.levelId}.png`);
    }
  },
  watch: {
    userInfo(val) {
      this.levelImage = require(`../../assets/images/level${this.userInfo.levelId}.png`);
    },
  },
  methods: {
    async getWorkTime() {
      const data = await api.getWorkTime({});
      this.startTime = data.startTime;
      this.endTime = data.endTime;
    },
    async getUserCurrentTask() {
      const data = await api.getUserCurrentTask({});
      this.taskNumber = data.orderSumNum;
      // console.log(data);
    },
    async submitOrder() {
      if (this.userInfo.balance < 0) {
        this.$dialog
          .alert({
            title: "Tips",
            message: `Current Balance: ${(this.userInfo.balance || 0).toFixed(
              2
            )}, Please recharge!`,
            confirmButtonText: "Sure",
          })
          .then(() => {
            this.$router.push("/platform/deposit");
          });
      } else {
        this.showLoading = true;
        window.setTimeout(async () => {
          try {
            const data = await api.submitOrder({ id: this.taskDetail.id });
            if (data && data.code === 508) {
              this.showLoading = false;
              this.$dialog
                .alert({
                  title: "Tips",
                  message: `Current Balance: ${(
                    this.userInfo.balance || 0
                  ).toFixed(2)}, Please recharge!`,
                  confirmButtonText: "Sure",
                })
                .then(() => {
                  this.$router.push("/platform/deposit");
                });
            } else {
              this.$toast.success("Submit is successful!");
              this.$emit("refreshUserInfo");
              this.getUserCurrentTask();
              this.showTask = false;
              this.showLoading = false;
            }
          } catch (e) {
            console.log(e);
            this.showLoading = false;
          }
        }, 1500);
      }
    },
    closeTask() {
      this.showTask = false;
    },
    showLeftMenu() {
      this.$emit("showLeftMenu");
    },

    async toStart() {
      if (this.userInfo.balance < 0) {
        this.$dialog
          .alert({
            title: "Tips",
            message: `Current Balance: ${(this.userInfo.balance || 0).toFixed(
              2
            )}, Please recharge!`,
            confirmButtonText: "Sure",
          })
          .then(() => {
            this.$router.push("/platform/deposit");
          });
      } else {
        try {
          this.showLoading = true;
          const data = await api.startTask({});
          this.showLoading = false;
          this.$emit("refreshUserInfo");
          this.showTask = true;
          this.taskDetail = data;
        } catch (e) {
          this.showLoading = false;
        }
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.main-page {
  padding: 90px 0 120px;
  overflow-x: hidden;

  h1.title {
    color: #26a6c6;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .marquee {
    color: #fff;
    width: 100%;
    height: 280px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    ul {
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      height: 100%;
      animation: move 15s linear infinite;

      &.last {
        animation-delay: 15s;
      }

      li {
        width: 230px;
        height: 280px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    @keyframes move {
      /* 为什么设置 -800px ？公式：主图张数 * 单个图片宽度（每轮主图只有 4 张，4 张照片为一个周期，如果需要轮播的主图为 7、8、9...张，都一样） */
      100% {
        transform: translateX(-1840px);
      }
    }
  }

  .app-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;

    .app-item {
      width: 20%;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child img {
        animation-delay: 0.2s;
      }

      &:nth-child(2) img {
        animation-delay: 0.4s;
      }

      &:nth-child(3) img {
        animation-delay: 0.6s;
      }

      &:nth-child(4) img {
        animation-delay: 0.8s;
      }

      &:nth-child(5) img {
        animation-delay: 1s;
      }

      &:nth-child(6) img {
        animation-delay: 1.2s;
      }

      &:nth-child(7) img {
        animation-delay: 1.4s;
      }

      &:nth-child(8) img {
        animation-delay: 1.6s;
      }

      &:nth-child(9) img {
        animation-delay: 1.8s;
      }

      &:nth-child(10) img {
        animation-delay: 2s;
      }

      &:nth-child(11) img {
        animation-delay: 2.2s;
      }

      &:nth-child(12) img {
        animation-delay: 2.4s;
      }

      &:nth-child(13) img {
        animation-delay: 2.6s;
      }

      &:nth-child(14) img {
        animation-delay: 2.8s;
      }

      &:nth-child(15) img {
        animation-delay: 3s;
      }

      img {
        width: 65px;
        height: 65px;
        animation: app-scale 3s infinite;
      }

      @keyframes app-scale {
        0%,
        to {
          width: 65px;
          height: 65px;
        }

        20% {
          width: 60px;
          height: 45px;
        }

        30% {
          width: 55px;
          height: 40px;
        }

        40% {
          width: 70px;
          height: 70px;
        }

        50% {
          width: 60px;
          height: 60px;
        }

        60% {
          width: 65px;
          height: 65px;
        }
      }
    }
  }

  .number {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .level {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    img {
      width: 30px;
      margin-right: 4px;
    }

    span {
      color: #2ca9c8;
      font-size: 13px;
      font-weight: 700;
    }
  }

  .start-button {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-image: linear-gradient(90deg, #25abc4, #1acbb5);
    background-size: cover;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    margin: 16px;
  }

  .price-info {
    padding: 0 16px;
    display: flex;

    .price-item {
      flex: 1;
      flex-grow: 1;
      border: 1px solid #26a6c6;
      border-radius: 3px;
      padding: 0 15px;
      height: 220px;
      margin-right: 16px;
      line-height: 1.6;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 70px;
        margin: 15px auto;
        display: block;
      }

      h3 {
        text-align: center;
        color: #2ca9c8;
        font-weight: 400;
      }

      h4 {
        text-align: center;
        color: #2ca9c8;
        margin: 15px 0 0;
      }

      h5 {
        text-align: center;
        color: #2ca9c8;
        margin: 15px 0 0;
      }

      .usdt {
        margin-left: 15px;
        text-align: left;
        color: #2ca9c8;
        font-size: 12px;
        font-weight: bold;
      }

      .frozen {
        margin-left: 15px;
        text-align: left;
        color: #2ca9c8;
        font-size: 13px;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        transform: scale(0.9);
        width: 120%;
        margin-left: -10%;
        text-align: center;
        color: #777;
      }
    }
  }

  .spe-tips {
    font-weight: 700;
    padding: 16px;
    line-height: 1.6;

    .title {
      font-size: 14px;
    }

    p {
      font-size: 12px;
      color: #888;
      line-height: 1.8;
      font-weight: 400;

      &::before {
        content: "";
        width: 1px;
        height: 1px;
        display: inline-block;
        background: #888;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.task-box {
  padding: 16px;

  h2.title {
    text-align: center;
    font-size: 15px;
    position: relative;
    color: #22b0c6;

    .close-icon {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 16px;
      font-weight: 700;
      color: rgb(102, 102, 102);
    }
  }

  .task-image {
    width: 135px;
    height: 135px;
    display: block;
    margin: 20px auto 10px;
  }

  h3.title {
    text-align: center;
    color: #777;
    font-size: 14px;
  }

  .price-box {
    display: flex;
    margin-top: 15px;
    border-bottom: 1px solid #22b0c6;
    padding-bottom: 20px;

    .price-item {
      flex: 1;
      flex-flow: 1;
      color: #22b0c6;
      line-height: 1.6;

      h4.title {
        font-size: 12px;
        text-align: center;
        margin: 0;
        font-weight: 400;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }

  .price-line {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #777;
    border-bottom: 1px solid #22b0c6;

    span {
      flex: 1;
      font-size: 12px;
    }

    p {
      color: #777;
      margin: 0;
    }
  }

  .button {
    line-height: 40px;
    line-height: 40px;
    text-align: center;
    background-image: linear-gradient(90deg, #25abc4, #1acbb5);
    background-size: cover;
    color: #fff;
    margin: 20px auto 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>