<template>
  <div class="page-withdraw">
    <my-header title="Deposit">
        <span name="right" class="header-right" @click="recharge">History</span>
    </my-header>
    <div class="account-box">
      <h3 class="title">Account Amount</h3>
      <h4 class="price-info">
        <span>USDT</span>
        <i>{{ (userInfo.balance || 0).toFixed(2) }}</i>
      </h4>
    </div>

    <div class="content">
      <div class="price-list">
        <div
          class="price-item"
          v-for="(item, index) in priceList"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="changeIndex(index)"
        >
          <h3 class="desc">USDT</h3>
          <span>{{ item.toFixed(2) }}</span>
        </div>
      </div>

      <div class="input-box">
        <input type="number" v-model="price" placeholder="Deposit Amount" />
      </div>

      <div class="button-submit" @click="showAction = true">Deposit Now</div>
    </div>

    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="Cancel"
      close-on-click-action
      @cancel="onCancel"
      @select="selectAction"
    />
  </div>
</template>
  <script>
import api from "@/api/api";
import MyHeader from "../../components/header.vue";
export default {
  components: { MyHeader },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      priceList: [100, 500, 1000],
      currentIndex: -1,
      price: "",
      actions: [
        { name: "Customer Service Whatsapp", key: "whatsapp" },
        { name: "Customer Service Telegram", key: "telegram" },
      ],
      showAction: false,
    };
  },
  created() {
    this.getCustomerList();
    console.log(this.userInfo);
  },
  methods: {
    async getCustomerList() {
      const data = await api.getCustomerList({});
      this.actions = (data || []).map((item) => {
        item.name = item.title;
        return item;
      });
    },
    selectAction(item) {
      if (item.url) {
        window.location.href = item.url;
      }
    },
    onCancel() {},
    changeIndex(index) {
      this.currentIndex = index;
      this.price = this.priceList[index];
    },
    recharge(){
        this.$router.push(`/platform/recharge`);
    },
  },
};
</script>
  <style lang="less" scoped>
.page-withdraw {
  padding: 90px 24px 24px;
}
.header-right {
  height: 44px;
  line-height: 44px;
  padding-right: 8px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}
.account-box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  height: 135px;
  background: url(../../assets/images/account-bg.png) no-repeat;
  background-size: cover;
  border-radius: 10px;
  line-height: 1.6;

  h3.title {
    color: #fff;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
  }

  h4.price-info {
    color: #fff;
    font-weight: 700;
    margin-top: 20px;
    span {
      font-size: 15px;
    }

    i {
      font-size: 22px;
      margin-left: 10px;
      font-style: normal;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }
}
.content {
  line-height: 1.6;

  .price-list {
    margin-top: 20px;
    display: flex;

    .price-item {
      flex: 1;
      flex-grow: 1;
      height: 74px;
      border: 1px solid #bde5ed;
      border-radius: 5px;
      padding: 10px 15px;
      box-sizing: border-box;
      color: #2fafc6;
      margin-right: 5px;

      &.active {
        background: #1dc0b9;
        border: 1px solid #1dc0b9;
        color: #fff;
      }
      h3.desc {
        font-weight: 400;
        font-size: 12px;
      }
      span {
        padding-left: 15px;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
  h3.title {
    color: #25abc3;
    font-weight: 700;
    margin-top: 16px;

    &.space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .button {
      width: 100px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      border-radius: 3px;
      background: #1dc0b9;
      border: 1px solid #49dad4;
      font-weight: 400;
    }
  }

  p.tips {
    font-size: 12px;
  }

  .input-box {
    height: 46px;
    border-radius: 8px;
    padding: 0 10px;
    background: #d8f4f3;
    margin-top: 10px;
    border: 0;

    input {
      height: 100%;
      width: 100%;
      color: #039f98;
      font-size: 16px;
      background: #d8f4f3;
      outline: none;
      border: 0;
    }
  }

  .button-submit {
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-image: linear-gradient(90deg, #25abc4, #1acbb5);
    background-size: cover;
    color: #fff;
    margin: 20px auto;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>